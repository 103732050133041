import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mqMin } from '@ui-v2/utils/styleUtils';

export const StyledDayPicker = styled.div(({ theme }) => [
  css`
    --cell-width: 38px;
    --cell-height: 50px;

    @media (width >= 375px) {
      --cell-width: 45px;
      --cell-height: 50px;
    }

    @media (width >= 414px) {
      --cell-width: 52px;
      --cell-height: 55px;
    }

    ${mqMin[768]} {
      --cell-width: 38px;
      --cell-height: 50px;
    }

    ${mqMin[960]} {
      --cell-width: 45px;
      --cell-height: 45px;
    }
    /* stylelint-disable selector-class-pattern */

    /* Buttons */
    .rdp-button_reset {
      padding: 0;
      border: none;
      margin: 0;
      appearance: none;
      background: none;
      color: inherit;
      cursor: pointer;
      font: inherit;
      outline: none;
    }

    .rdp-months {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacings[40]}px;

      @media (width >= 768px) {
        flex-direction: row;
      }
    }

    .rdp-head_cell {
      width: var(--cell-width);
      font-size: ${theme.typography.body02.fontSize}px;
      font-weight: 400;
    }

    .rdp-caption {
      position: relative;
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;
      margin-bottom: ${theme.spacings[4]}px;
      text-align: center;

      .rdp-nav {
        position: absolute;
        display: flex;
        justify-content: space-between;

        button {
          width: 40px;
          height: 40px;
        }
      }

      .rdp-caption_label {
        font-weight: 600;
      }
    }

    .rdp-caption_start .rdp-nav {
      left: 0;
    }

    .rdp-caption_end .rdp-nav {
      right: 0;
    }

    .rdp-row,
    .rdp-head_row {
      display: flex;
      margin-bottom: ${theme.spacings[4]}px;
      gap: ${theme.spacings[4]}px;

      &.rdp-row:last-child {
        margin-bottom: 0;
      }
    }

    .rdp-cell {
      display: flex;
      width: var(--cell-width);
      height: var(--cell-height);
      padding: 0;
    }

    .rdp-button {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 2px solid transparent;
      border-radius: ${theme.shape.cardPrimary}px;
      background: ${theme.colours.surface.selected.default};
      color: ${theme.colours.components.date.text.default};
      transition:
        background-color 100ms,
        color 100ms,
        border-color 50ms;

      &.rdp-day_disabled {
        background: ${theme.colours.surface.disabled};
        color: ${theme.colours.components.date.text.disabled};
        cursor: not-allowed;
      }

      &:hover,
      &.rdp-day_selected {
        background: ${theme.colours.surface.selected.hover};
        color: ${theme.colours.components.date.text.default};
      }

      &:focus {
        border-color: ${theme.colours.interactive.focused};
      }

      &.rdp-day_range_start,
      &.rdp-day_range_end:not(.rdp-day_disabled) {
        background: ${theme.colours.surface.selected.pressed};
        color: ${theme.colours.components.date.text.selected};

        &:focus {
          border-color: ${theme.colours.interactive.focused};
        }
      }
    }

    /* stylelint-enable selector-class-pattern */
  `,
]);
